import React from "react";
import GalleryPage from "../../../components/galleryPages/GalleryPage";

const Gallery = () => {
  return (
    <div>
      <GalleryPage name="exterior" />
    </div>
  );
};

export default Gallery;
